import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { UserContext } from '../contexts/user'
import { Modal } from './modal'
import createOrgMutation from '../api/createOrg.graphql'

const CreateOrg = ({
  onCreated,
}: {
  onCreated: (newOrg: { id: string; name: string }) => void
}) => {
  const { setUser } = useContext(UserContext)
  const [name, setName] = useState('')
  const [createOrg, { error, loading }] = useMutation(createOrgMutation, {
    onCompleted(data) {
      if (data.success && data.organization) {
        setUser((user) => ({
          ...user,
          organizations: user.organizations.concat(data.organization),
        }))
        onCreated(data.organization)
      }
    },
  })

  const onCreateOrg = (event: React.MouseEvent) => {
    event.preventDefault()
    if (!name) {
      return
    }
    createOrg({ variables: { name } })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Modal>
      <div>
        <input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          placeholder="Organization's name"
        />
        {error ? <pre>{JSON.stringify(error, null, '  ')}</pre> : null}
        <button onClick={onCreateOrg} disabled={!name.length}>
          Create organization
        </button>
      </div>
    </Modal>
  )
}

export default CreateOrg
