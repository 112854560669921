import React, { useRef, useCallback, useContext, useState } from 'react'
import { navigate } from 'gatsby'
import { UserContext } from '../contexts/user'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CreateOrg from '../components/create-org'

import './index.css'

const DashboardPage = () => {
  const { user, setUser } = useContext(UserContext)
  const [showingCreateOrgModal, showCreateOrgModal] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(
    user ? user.organizations[0]?.id : undefined
  )

  const logout = useCallback(() => {
    setUser(null)
  }, [setUser])

  const onChangeOrg = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      if (ev.target.value === '__new__org') {
        event.preventDefault()
        showCreateOrgModal(true)
        return
      }
      setSelectedOrg(ev.target.value)
    },
    [setSelectedOrg, showCreateOrgModal]
  )

  if (!user) {
    if (typeof window !== 'undefined') navigate('/login')
    return null
  }

  return (
    <Layout>
      <SEO title="Lona" />
      <h1>Welcome {user.username}</h1>
      <button onClick={logout}>Log out</button>
      {!user.organizations.length ? (
        <div>
          <p>You aren't part of any organization yet. Start by creating one.</p>
          <button onClick={() => showCreateOrgModal(true)}>
            Create organization
          </button>
        </div>
      ) : (
        <div>
          <h2>Organizations</h2>
          <select value={selectedOrg} onChange={onChangeOrg}>
            {user.organizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
            <option value="__new__org">Create new organization</option>
          </select>
        </div>
      )}
      {showingCreateOrgModal ? (
        <CreateOrg onCreated={(newOrg) => setSelectedOrg(newOrg.id)} />
      ) : null}
    </Layout>
  )
}

export default DashboardPage
