import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

export const Modal = ({ children }) => {
  const el = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(el.current)
    return () => document.body.removeChild(el.current)
  }, [])

  return createPortal(children, el.current)
}
